import { useMemo } from 'react'

import { Card, Container, Flex, Group, Loader, createStyles } from '@mantine/core'
import type { NextPage } from 'next'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mantine/hooks'
import { StylesHelper } from '@testsa/helpers/Styles.helper'
import { useRouter } from 'next/router'
import type {
  ClientUserPermissionsAttributesApiDTO,
  UserPermissionsAttributesApiDTO,
} from 'src/codegen/generated/Api.schemas'
import { useGetApiV1UserMe } from 'src/codegen/generated/user'
import { TSAHero } from 'src/components/TSAHero'
import { TSAQuickAccessButton } from 'src/components/TSAQuickAccessButton'
import { MenuHelper, type MenuLink } from 'src/helpers/Menu.helper'

const useStyles = createStyles(theme => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontWeight: 700,
  },
}))

const Index: NextPage = () => {
  const { t } = useTranslation('pages')
  const { t: tc } = useTranslation('common')
  const { classes, theme } = useStyles()
  const router = useRouter()
  const mobileScreen = useMediaQuery(StylesHelper.MOBILE)

  const links = useMemo(
    () =>
      MenuHelper.getLinks(tc).reduce((arr, curr) => {
        let newArr = arr
        if (curr.subLinks) {
          newArr = [...arr, ...curr.subLinks]
        } else {
          newArr.push(curr)
        }

        return newArr.filter(a => ['/projects/', '/pollsters/', '/field/'].includes(a.link ?? ''))
      }, [] as MenuLink[]),
    [tc],
  )
  const meQuery = useGetApiV1UserMe()

  if (meQuery.data?.body.type === 'clientUser') {
    router.push(`/dashboards/organisations/${meQuery.data?.body.organization.id}`)

    return
  }

  if (meQuery.isLoading) {
    return (
      <Group position="center" pt="xl">
        <Loader size="sm" />
      </Group>
    )
  }

  return (
    <>
      <TSAHero title={t('index.title')} description={t('index.description')} />
      <Container size="md">
        <Card withBorder radius="md" className={classes.card}>
          <Flex gap={16} wrap="wrap">
            {links.map((link, index) => (
              <TSAQuickAccessButton
                key={link.link}
                color={theme.colors.orange[6]}
                hasPermission={
                  meQuery.data?.body.permissions?.[
                    link.permission as keyof (
                      | UserPermissionsAttributesApiDTO
                      | ClientUserPermissionsAttributesApiDTO
                    )
                  ]
                }
                style={{
                  flexBasis: mobileScreen || index === links.length - 1 ? '100%' : '49%',
                }}
                {...link}
              />
            ))}
          </Flex>
        </Card>
      </Container>
    </>
  )
}

export default Index
